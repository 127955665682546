import {
  GraphPanelStyleDownstream,
  LazyGrafanaPanel,
  RankingPanelStyleDownstream,
  SingleValuePanelStyleDownstream
} from '../Components/GrafanaPanel';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useGrafanaLink } from '../Dashboard/Dashboard';
import { Cmts, CmtsSchedule } from '../Api/Types/Config';
import { DsChannel, MacDomain, UsChannel } from '../Api/Types/Topology';
import { CmtsSelectorComponent } from '../Components/Selectors/CmtsSelectorComponent';
import { MacDomainSelectorComponent } from '../Components/Selectors/MacDomainSelectorComponent';
import { ChannelSelectorComponent } from '../Components/Selectors/ChannelSelectorComponent';
import { ApiGet, ChannelDirection, isListApiResponse } from '../Api/Util';
import { ListApiResponse } from '../Api/Types/General';
import { PmaDsResult } from '../Api/Types/CapacityBooster';
import { ApiRoutes } from '../Api/ApiRoutes';
import { ProfileSetDisplay } from '../Deployment/Channel/ProfileSetDisplay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFeedbackContext } from '../Feedback/FeedbackContext';
import { FakeGrafanaPanel } from '../Components/FakeGrafanaPanel';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SquareIcon from '@mui/icons-material/Square';
import { ViewActionButton } from '../Components/ViewActionButton';
import { PLCTable } from './PLCTable';

const DownstreamPanelIDs = {
  streamDirection: ChannelDirection.Downstream,
  ChannelParameters: 18,
  ChannelInfo: 103,
  ThreePointOneCMs: 100,
  EffectiveChannelSpeed: 105,
  LatestImpariedCMsDetail: 117,
  ChannelFecBy: 19,
  Map: 115,
  ChannelCmStatus: 119,
  ChannelUtilization: 76,
  CodewordBitloadingWeightedByProfile: 121,
  TotalCodewordsByProfile: 123,
  AssignedCMsByProfile: 33,
  TotalCWs: 125
};

const UpstreamPanelIDs = {
  streamDirection: ChannelDirection.Upstream,
  ChannelParameters: 24,
  ChannelInfo: 208,
  ThreePointOneCMs: 210,
  EffectiveChannelSpeed: 113,
  LatestImpariedCMsDetail: 109,
  ChannelFecBy: 225,
  Map: 214,
  ChannelCmStatus: 161,
  ChannelUtilization: 54,
  CodewordBitloadingWeightedByIuc: 135,
  TotalCodewordsByIuc: 148,
  AssignedCMsByProfile: 221,
  TotalCWs: 219
};

type GrafanaStreamProp = {
  stream: any;
  extraParams: any;
  loading: boolean;
  cmtsId?: number;
  macDomainIfIndex?: number;
  channelIfIndex?: number;
};

function GrafanaStream({ stream, extraParams, loading, cmtsId, macDomainIfIndex, channelIfIndex }: GrafanaStreamProp): JSX.Element {
  const isDownstream = stream.streamDirection === ChannelDirection.Downstream;
  const dashboardUrl = isDownstream
    ? '/grafana/d-solo/UEojLdw7k/operational-downstream-channel'
    : '/grafana/d-solo/WUXojLdw3m/operational-upstream-channel';
  const channelRxmerUrl = isDownstream
    ? '/grafana/d-solo/pkpIPDl7k/operational-downstream-channel-rxmer'
    : '/grafana/d-solo/Os6m0A_nk/operational-upstream-channel-rxmer';
  const techSupervisorUrl = '/grafana/d-solo/9ojKsPx4z/tech-supervisor-downstream-channel';
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const SingleValuePanel = SingleValuePanelStyleDownstream;
  const GraphPanel = GraphPanelStyleDownstream;
  const RankingPanel = RankingPanelStyleDownstream;

  return (
    <>
      <Grid xs={12} display="flex" gap={1} flexDirection={matchSM ? 'column' : 'row'}>
        <Grid xs={12} lg={4} sx={{ p: 0 }}>
          <Grid xs={12} lg={12} sx={{ p: 0 }}>
            <LazyGrafanaPanel
              url={channelRxmerUrl}
              panelId={stream.ThreePointOneCMs}
              width="100%"
              height="100%"
              injectedClassName="single-value-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
          {isDownstream ? (
            <Grid xs={12} lg={12} sx={{ p: 0 }}>
              <LazyGrafanaPanel
                url={channelRxmerUrl}
                panelId={stream.EffectiveChannelSpeed}
                width="100%"
                height="100%"
                injectedClassName="single-value-panel"
                injectedStyle={SingleValuePanel}
                extraParams={extraParams}
                loading={loading}
              />
            </Grid>
          ) : (
            <Grid xs={12} lg={12} sx={{ p: 0 }}>
              <FakeGrafanaPanel
                name={t('rxmer_view.effective_speed')}
                description={t('rxmer_view.coming_soon')}
                isDownstream={null}
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12} lg={8} sx={{ p: 0 }}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.ChannelFecBy}
            width="100%"
            height="310px"
            injectedClassName="single-value-panel"
            injectedStyle={SingleValuePanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Accordion sx={{ width: '100%' }} disableGutters elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#FEFEFE' }} />}
            aria-controls="map-content"
            id="map-header"
            sx={{
              justifyContent: 'start',
              borderRadius: '8px',
              height: '40px',
              flexDirection: 'row-reverse',
              backgroundColor: theme => theme.colors.direction.downstream,
              '& .MuiAccordionSummary-content': {
                flexGrow: '0',
                alignItems: 'center',
                gap: '5px',
                marginLeft: '5px'
              }
            }}
          >
            <PlaceOutlinedIcon sx={{ color: '#FEFEFE' }} />
            <Typography variant="h5" color={'#FEFEFE'}>
              {t('rxmer_view.map')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LazyGrafanaPanel
              url={channelRxmerUrl}
              panelId={stream.Map}
              width="100%"
              height="310px"
              injectedClassName="single-value-panel"
              injectedStyle={SingleValuePanel}
              extraParams={extraParams}
              loading={loading}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? 'column' : 'row'}>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.ChannelInfo}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={dashboardUrl}
            panelId={stream.ChannelParameters}
            width="100%"
            height="300px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      {isDownstream &&
        <PLCTable
          cmtsId={Number(cmtsId)}
          macDomainIfIndex={Number(macDomainIfIndex)}
          channelIfIndex={Number(channelIfIndex)}
        />
      }
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? 'column' : 'row'}>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.ChannelCmStatus}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.ChannelUtilization}
            width="100%"
            height="300px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? 'column' : 'row'}>
        {isDownstream ? (
          <Grid xs={12} lg={6}>
            <LazyGrafanaPanel
              url={channelRxmerUrl}
              panelId={stream.CodewordBitloadingWeightedByProfile}
              width="100%"
              height="300px"
              injectedClassName="ranking-panel"
              injectedStyle={RankingPanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
        ) : (
          <Grid xs={12} lg={6}>
            <LazyGrafanaPanel
              url={channelRxmerUrl}
              panelId={stream.CodewordBitloadingWeightedByIuc}
              width="100%"
              height="300px"
              injectedClassName="ranking-panel"
              injectedStyle={RankingPanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
        )}
        {isDownstream ? (
          <Grid xs={12} lg={6}>
            <LazyGrafanaPanel
              url={channelRxmerUrl}
              panelId={stream.TotalCodewordsByProfile}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={GraphPanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
        ) : (
          <Grid xs={12} lg={6}>
            <LazyGrafanaPanel
              url={channelRxmerUrl}
              panelId={stream.TotalCodewordsByIuc}
              width="100%"
              height="300px"
              injectedClassName="graph-panel"
              injectedStyle={GraphPanel}
              extraParams={extraParams}
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
      <Grid xs={12} display="flex" gap={1} padding="0" flexDirection={matchMD ? 'column' : 'row'}>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.AssignedCMsByProfile}
            width="100%"
            height="300px"
            injectedClassName="ranking-panel"
            injectedStyle={RankingPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} lg={6}>
          <LazyGrafanaPanel
            url={channelRxmerUrl}
            panelId={stream.TotalCWs}
            width="100%"
            height="300px"
            injectedClassName="graph-panel"
            injectedStyle={GraphPanel}
            extraParams={extraParams}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
}

type IdMap<T> = { [key: string]: T };

export function RxmerView(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { setGrafanaLink, setGrafanaLinkButton } = useGrafanaLink();

  const DsDashboardLinkUrl = '/grafana/d/UEojLdw7k/operational-downstream-channel';
  const UsDashboardLinkUrl = '/grafana/d/WUXojLdw3m/operational-upstream-channel';

  const [cmtsIdToCmts, setCmtsIdToCmts] = useState<IdMap<Cmts>>({});
  const [macDomainIdToMacDomain, setMacDomainIdToMacDomain] = useState<IdMap<MacDomain>>({});
  const [channelIdToChannel, setChannelIdToChannel] = useState<IdMap<DsChannel | UsChannel>>({});
  const [pmaResult, setPmaResult] = useState<PmaDsResult | null>(null);
  const [cmtsInfo, setCmtsInfo] = useState<Cmts | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams({
    cmts: '',
    macdomain: '',
    channel: ''
  });

  useEffect(() => {
    setGrafanaLinkButton(true);
  }, []);

  const { feedbackError } = useFeedbackContext();

  const cmtsParam: string | null = searchParams.get('cmts');
  const [currentCmtsId, setCurrentCmtsId] = useState(cmtsParam ? cmtsParam : '');
  const macDomainParam = searchParams.get('macdomain');
  const [currentMacDomainId, setCurrentMacDomainId] = useState(
    macDomainParam ? macDomainParam : ''
  );

  const channelParam: string | null = searchParams.get('channel');
  const [currentChannelId, setCurrentChannelId] = useState(channelParam ? channelParam : '');

  const cmstName = Object.hasOwn(cmtsIdToCmts, currentCmtsId)
    ? cmtsIdToCmts[currentCmtsId].name
    : '';
  const macDomainIfDescr = Object.hasOwn(macDomainIdToMacDomain, currentMacDomainId)
    ? macDomainIdToMacDomain[currentMacDomainId].ifDescr
    : '';
  const channelIfDescr = Object.hasOwn(channelIdToChannel, currentChannelId)
    ? channelIdToChannel[currentChannelId].ifDescr
    : '';

  const macDomainIfIndex = Object.hasOwn(macDomainIdToMacDomain, currentMacDomainId)
    ? macDomainIdToMacDomain[currentMacDomainId].ifIndex.toString()
    : '';
  const channelIfIndex = Object.hasOwn(channelIdToChannel, currentChannelId)
    ? channelIdToChannel[currentChannelId].ifIndex.toString()
    : '';

  const currentChannelDirection = Object.hasOwn(channelIdToChannel, currentChannelId)
    ? channelIdToChannel[currentChannelId].ifDescr.toLowerCase().includes('downstream')
      ? ChannelDirection.Downstream
      : ChannelDirection.Upstream
    : '';

  useEffect(() => {
    if (currentCmtsId !== '' && currentMacDomainId !== '' && currentChannelId !== '') {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [currentCmtsId, currentMacDomainId, currentChannelId]);

  useEffect(() => {
    {
      !loading &&
        ApiGet<Cmts>(ApiRoutes.config.cmts.single(Number(currentCmtsId)))
          .then(result => {
            setCmtsInfo(result);
          })
          .catch(error => {
            console.log({
              route: 'ApiRoutes.config.cmts',
              cmtsIdNum: Number(currentCmtsId),
              error: error
            });
            feedbackError(t('deploy.failed_fetching_Cmts', { message: error.message }));
          });
    }
  }, [currentCmtsId, loading]);

  useEffect(() => {
    const url =
      currentChannelDirection === ChannelDirection.Downstream
        ? ApiRoutes.cb.dsProfileSet
        : ApiRoutes.cb.usProfileSet;
    ApiGet<ListApiResponse<PmaDsResult>>(
      url.filter({
        latest: true,
        cmtsId: Number(currentCmtsId),
        macDomainIfIndex: Number(macDomainIfIndex),
        channelIfIndex: Number(channelIfIndex)
      })
    ).then(async (response: ListApiResponse<PmaDsResult>) => {
      if (isListApiResponse<PmaDsResult>(response.results) && response.count == 1) {
        setPmaResult(response.results[0]);
      } else {
        setPmaResult(null);
      }
    });
  }, [currentCmtsId, macDomainIfIndex, channelIfIndex]);

  useEffect(() => {
    setSearchParams({
      cmts: currentCmtsId,
      macdomain: currentMacDomainId,
      channel: currentChannelId
    });
    if (currentChannelDirection === ChannelDirection.Downstream) {
      setGrafanaLink(
        `${DsDashboardLinkUrl}?var-cmts=${cmstName}&var-mac_dom=${macDomainIfDescr}&var-ds_chan=${channelIfDescr}`
      );
    } else {
      setGrafanaLink(
        `${UsDashboardLinkUrl}?var-cmts=${cmstName}&var-mac_dom=${macDomainIfDescr}&var-us_chan=${channelIfDescr}`
      );
    }
  }, [currentCmtsId, currentMacDomainId, currentChannelId, currentChannelDirection]);

  const extraParams = {
    'var-cmts': cmstName,
    'var-mac_dom': macDomainIfDescr,
    'var-ds_chan': channelIfDescr
  };

  const channelUrl = `/channel-view/?cmts=${currentCmtsId}&macdomain=${currentMacDomainId}&channel=${currentChannelId}`;

  return (
    <Grid container spacing={2} id="rxmer-view-content">
      <Grid
        xs={12}
        sx={{
          display: 'flex',
          padding: 0,
          flexDirection: { sm: 'column', md: 'row' }
        }}
      >
        <CmtsSelectorComponent
          width={3}
          currentCmtsId={currentCmtsId}
          setCurrentCmtsId={setCurrentCmtsId}
          cmtsIdToCmts={cmtsIdToCmts}
          setCmtsIdToCmts={setCmtsIdToCmts}
          firstItem
        />
        <MacDomainSelectorComponent
          width={3}
          cmtsId={currentCmtsId}
          currentMacDomainId={currentMacDomainId}
          setCurrentMacDomainId={setCurrentMacDomainId}
          macDomainIdToMacDomain={macDomainIdToMacDomain}
          setMacDomainIdToMacDomain={setMacDomainIdToMacDomain}
          firstItem
        />
        <ChannelSelectorComponent
          width={3}
          cmtsId={currentCmtsId}
          macDomainIfIndex={macDomainIfIndex}
          currentChannel={currentChannelId}
          setCurrentChannel={setCurrentChannelId}
          channelIdToChannel={channelIdToChannel}
          setChannelIdToChannel={setChannelIdToChannel}
          firstItem
        />
        <ViewActionButton width={3} url={channelUrl} name={t('rxmer_view.manage_profiles')} />
      </Grid>
      {currentChannelDirection === ChannelDirection.Downstream ? (
        <Grid xs={12}>
          <GrafanaStream
            stream={DownstreamPanelIDs}
            extraParams={extraParams}
            loading={loading}
            cmtsId={Number(currentCmtsId)}
            macDomainIfIndex={Number(macDomainIfIndex)}
            channelIfIndex={Number(channelIfIndex)}
          />
        </Grid>
      ) : (
        <Grid xs={12}>
          <GrafanaStream stream={UpstreamPanelIDs} extraParams={extraParams} loading={loading} />
        </Grid>
      )}
      <Grid xs={12}>
        <Card
          elevation={0}
          sx={{ borderRadius: '8px', height: '100%', backgroundColor: '#FEFEFE', color: '#5C749A' }}
        >
          <CardHeader
            title={t('rxmer_view.subcarrier_legend')}
            sx={{
              paddingBottom: 0,
              '& .MuiCardHeader-title': {
                fontSize: '1.25rem',
                lineHeight: '1',
                webkitTextStroke: '0.5px'
              }
            }}
          />
          <CardContent sx={{ display: 'flex', gap: 2, flexDirection: matchMD ? 'column' : 'row' }}>
            <Stack alignItems="center" direction="row" gap={1}>
              <SquareIcon sx={{ color: '#007700' }} />
              <Typography sx={{ color: '#5C749A' }}>{t('rxmer_view.plc')}</Typography>
            </Stack>
            <Stack alignItems="center" direction="row" gap={1}>
              <SquareIcon sx={{ color: '#e4f6e4' }} />
              <Typography sx={{ color: '#5C749A' }}>{t('rxmer_view.continuous')}</Typography>
            </Stack>
            <Stack alignItems="center" direction="row" gap={1}>
              <SquareIcon sx={{ color: '#999999' }} />
              <Typography sx={{ color: '#5C749A' }}>{t('rxmer_view.excluded')}</Typography>
            </Stack>
            <Stack alignItems="center" direction="row" gap={1}>
              <SquareIcon sx={{ color: '#777777' }} />
              <Typography sx={{ color: '#5C749A' }}>{t('rxmer_view.unused')}</Typography>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      {pmaResult && (
        <Grid xs={12}>
          <Accordion
            sx={{
              width: '100%',
              backgroundColor: theme => theme.colors.background.main
            }}
            disableGutters
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="rxmer-profile-content"
              id="rxmer-profile-header"
              sx={{
                justifyContent: 'start',
                padding: 0,
                flexDirection: 'row-reverse',
                gap: '10px',
                '& .MuiAccordionSummary-content': {
                  flexGrow: '0'
                }
              }}
            >
              <Typography variant="h5">{t('rxmer_view.rxmer_by_profile')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProfileSetDisplay
                pmaResult={pmaResult}
                downstream={currentChannelDirection === ChannelDirection.Downstream ? true : false}
                cmtsInfo={cmtsInfo}
                macDomainIfDescr={macDomainIfDescr}
                channelIfDescr={channelIfDescr}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  );
}
